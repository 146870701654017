import React, { useEffect } from 'react';
import { useIntl } from '../../../../util/reactIntl';
import classNames from 'classnames';
import { shuffle } from 'lodash';

import Field, { hasDataInFields } from '../../Field';
import BlockBuilder from '../../BlockBuilder';
import { ListingCard } from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionRecommendedListings.module.css';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};
const getResponsiveImageSizes = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.responsiveImageSizes : COLUMN_CONFIG[0].responsiveImageSizes;
};

/**
 * Section component that's able to show blocks in a carousel
 * the number blocks visible is defined by "numColumns" prop.
 *
 * @component
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.sectionId - Unique identifier for the section. **Required**.
 * @param {string?} props.className - Additional CSS class for the component.
 * @param {string?} props.rootClassName - Main CSS class for the component.
 * @param {Object?} props.defaultClasses - Default CSS classes for various parts of the section.
 * @param {string?} props.defaultClasses.sectionDetails - Class for section details.
 * @param {string?} props.defaultClasses.title - Class for the title.
 * @param {string?} props.defaultClasses.description - Class for the description.
 * @param {string?} props.defaultClasses.ctaButton - Class for the call-to-action button.
 * @param {number?} props.numColumns - Number of columns to display in the section.
 * @param {Object?} props.title - React element or configuration object for the title.
 * @param {Object?} props.description - React element or configuration object for the description.
 * @param {Object?} props.appearance - Appearance customization options for the section.
 * @param {Object?} props.callToAction - Configuration for the call-to-action button.
 * @param {Array<Object>?} props.blocks - List of recommended content blocks.
 * @param {Object?} props.options - Additional configuration options for the component.
 * @param {Object} props.options.fieldComponents - Configuration for field components.
 * @param {React.ReactNode} props.options.fieldComponents.component - React component to render.
 * @param {Function} props.options.fieldComponents.pickValidProps - Function to filter valid props for the component.
 *
 * @returns {JSX.Element} The SectionRecommendedListings component.
 */
const SectionRecommendedListings = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    numColumns = 1,
    title,
    description,
    appearance,
    callToAction,
    blocks = [],
    options,
    listings = [],
  } = props;
  const intl = useIntl();
  const sliderContainerId = `${props.sectionId}-container`;
  const sliderId = `${props.sectionId}-slider`;
  const numberOfBlocks = listings?.length;
  const hasListings = listings.length > 0;

  useEffect(() => {
    const setCarouselWidth = () => {
      if (hasListings) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          elem.clientWidth >= windowWidth - scrollbarWidth ? windowWidth : elem.clientWidth;
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
      }
    };
    setCarouselWidth();

    window.addEventListener('resize', setCarouselWidth);
    return () => window.removeEventListener('resize', setCarouselWidth);
  }, [listings]);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description], fieldOptions);
  const hasCTAField = hasDataInFields([callToAction], fieldOptions);

  const onSlideLeft = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onSlideRight = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numColumns * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e);
    }
  };

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
        </header>
      ) : null}
      {hasListings ? (
        <div className={css.carouselContainer} id={sliderContainerId}>
          <div
            className={classNames(css.carouselArrows, {
              [css.notEnoughBlocks]: numberOfBlocks <= numColumns,
            })}
          >
            <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
              ‹
            </button>
            <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
              ›
            </button>
          </div>
          <div className={getColumnCSS(numColumns)} id={sliderId}>
            {shuffle(listings).map(l => (
              <ListingCard rootClassName={css.block} key={l.id.uuid} listing={l} intl={intl} />
            ))}
          </div>
        </div>
      ) : null}
      {hasCTAField ? (
        <div className={defaultClasses.sectionDetails}>
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </div>
      ) : null}
    </SectionContainer>
  );
};

export default SectionRecommendedListings;
